<template>
  <div class="class-list">
   
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From/>
      </div>
      <Table  ref="table" />
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
import _api from "@/utils/request";
export default {
  name: "class-list",
  components: {
    From,
    Table,
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list {
  .button_top {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn {
    width: 280px;
    height: 32px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .left {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981ff;
      border-radius: 20px;
    }
    .right {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }
  .balance {
    display: flex;
    // flex-direction: column;
    // flex: 1;
    // margin-left: 20px;
    align-items: center;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    color: #666666;
    .tag {
      box-sizing: border-box;
      width: max-content;
      height: 16px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 6px;
    }
    .tag.blue {
      color: #0981ff;
      border: 1px solid #0981ff;
    }
    .tag.yellow {
      color: #ffaa1d;
      border: 1px solid #ffaa1d;
    }
  }
  .cost {
    font-size: 16px;
    color: #fb7b7b;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 20px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .accountForm {
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .tagBlue {
    box-sizing: border-box;
    width: max-content;
    height: 25px;
    line-height: 25px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 15px;
    color: #0981ff;
    border: 1px solid #0981ff;
  }
}
</style>
