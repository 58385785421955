<template>
  <div class="GlobalTable">
    <p>平台借款账单</p>
    <div class="table_static">
      <p>当前账单(借出{{choose.lendBalance}}元，还入{{choose.returningBalance}}元，净入账{{choose.netIncomeBalance}}元)</p>
      <p>今日(借出{{today.lendBalance}}元，还入{{today.returningBalance}}元，净入账{{today.netIncomeBalance}}元)</p>
      <p>本月(借出{{currentMonth.lendBalance}}元，还入{{currentMonth.returningBalance}}元，净入账{{currentMonth.netIncomeBalance}}元)</p>
    </div>
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="账单流水号" slot="uniqNo" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.serType == '62'" style="color: red;">{{ row.uniqNo || "--" }}</span>
          <span v-else>{{ row.uniqNo || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单号" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <div style="color: #0981ff;cursor: pointer;" v-if="row.orderChannel == 'recycle'" @click="toOrderDetails(row)">
            <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo }}</span></div>
          <span v-else>{{ row.orderNo || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="授信商家" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.merchantName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="账单类型" slot="opt" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="金额" slot="amount" align="center">
        <template slot-scope="{ row }">
          <span :style="row.opt == '01' ? 'color:green' : 'color:red'" v-if="row.opt != '03'">{{ row.amount>=0 && row.opt ==
            '01' ? `+${row.amount}` : `-${row.amount}` }}</span>
          <span :style="row.serType == '23' ? 'color:green' : 'color:red'" v-else>{{ row.amount && row.serType ==
            '23' ? `+${row.amount}` : `-${row.amount}` }}</span>
        </template>
      </el-table-column>
      <el-table-column label="当时平台余额" slot="nowBalance" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.afterBalance || "--" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="账单备注" width="130rpx" slot="remark" align="center">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
            <div class="Remarks">
              {{ row.remark || "--" }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </GlobalTable>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  props: {},
  data() {
    return {
      choose:{},
      currentMonth:{},
      today:{},
      loading: false,
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10
      },
      pageNum: 1,
      tableColumns: [
        { slotName: "uniqNo" },
        { slotName: "orderNo" },
        { slotName: "merchantName" },
        { label: "账单时间", prop: "createTime" },
        { slotName: "opt" },
        { label: "子类型", prop: "title" },
        { slotName: "companyName" },
        { slotName: "storeName" },
        { slotName: "amount" },
        { slotName: "nowBalance" },
        { slotName: "remark" }
      ]
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.handleCurrentChange()
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        accountType: "platformFinance",
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        uniqNo: this.SeachParams.uniqNo,
        orderNo: this.SeachParams.orderNo,
        opt: this.SeachParams.opt,
        serType: this.SeachParams.serType,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.getFinanceList(baseRequest).then(res => {
        console.log(res)
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.today=res.data.today
          this.currentMonth=res.data.currentMonth
          this.choose=res.data.choose
          this.loading = false
        }
      }).finally(() => { 
        this.loading = false
        if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
     })
    },
    toOrderDetails(row) {
      this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.orderNo } })
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }
}
.table_static{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  margin: 20px 0;
  padding: 15px 10px;
  background-color: #f2f2f2;
  color: red;
  font-size: 14px;
}
</style>
