<template>
  <div class="GlobalFormDemo">
    <GlobalForm ref="form" :needBtnLoading="true" :init-data="initData" :form-item-list="formItemList" :inline="true" :labelWidth="90" round
      @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label-width="75px" label="借款商家" slot="operationSelect" style="margin:0">
        <el-select v-model="merchantId" placeholder="请选择回收商" filterable clearable @change="handleChange">
          <el-option v-for="item in shanpsSelectList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="订单编号" label-width="70px" slot="orderNo" style="margin:0">
        <el-input v-model="orderNo" clearable placeholder="请输入订单号查询"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="账单类型" slot="orderNo" style="margin:0">
        <el-select v-model="opt" placeholder="请选择账单类型查询" filterable clearable @change="handleChangeOpt">
          <el-option v-for="item in optList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="账单子类型" slot="serTypeList" style="margin:0">
        <el-select v-model="serType" placeholder="请选择账单子类型" filterable clearable @change="handleChangeSerType">
          <el-option v-for="item in serTypeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-button icon="el-icon-download" slot="AddSlot" type="success" :loading="exportLoading" @click="exportBill">导出账单</el-button>
    </GlobalForm>
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String
    },
    info: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      isCustomUser: "",
      initData: {
          entryTime: [
            moment().day(moment().day()).format("YYYY-MM-DD 00:00:00"),
            moment().format("YYYY-MM-DD 23:59:59"),
          ],
        },
      merchantId: "",
      companyId: "",
      storeId: "",
      opt: "",
      shanpsSelectList: [],
      InquirySelectList: [],
      storeList: [],
      serTypeList: [],
      serType: "",
      orderNo: "",
      isHasCompany: false,
      day31: 30 * 24 * 3600 * 1000,
      pickerMinDate: null,
      formItemList: [
        { slotName: 'operationSelect' },
        {
          key: "uniqNo",
          type: "input",
          labelName: "账单流水号",
          placeholder: "请输入账单流水号查询",
        },
        { slotName: 'orderNo' },
        { slotName: 'opt' },
        { slotName: 'serTypeList' },
        {
                key: "entryTime",
                type: "pickerOptions",
                labelName: "账单时间",
                clearable: true,
                lableWidth: '50',
                placeholder: "请选择时间",
                valueFormat: "timestamp",
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                    },
                    disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                        time.getTime() > this.pickerMinDate + this.day31 ||
                        time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                    },
                },
            }
      ],
      optList: [
        {
          value: '01',
          label: '入账',
        },
        {
          value: '02',
          label: '出账',
        },
        {
          value: '03',
          label: '挂账',
        },
      ],
      transferData: {
        amountPrice: "",
      },
      NumMoney: "",
      butLoading: false,
      dialogVisible: false,
      exportLoading: false,
    };
  },
  created() {
    this.orderNo = this.$route.query.id || ""
    if(this.orderNo){
      this.initData.entryTime=null
    }
    _api.listSimpleMerchantView( {
      pageNum: 1,
      pageSize: 9999,
    }).then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data
      }
    })
    _api.getSerTypeList({ opt: this.opt,accountType: 'platformFinance' }).then((res) => {
      if (res.code === 1) {
        this.serTypeList = res.data
      }
    })
    this.handleConfirm(this.initData);
  },

  watch: {
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data,this.entryTime);
      let SeachParams = {};
      if (this.orderNo) {
        SeachParams = {
          orderNo: this.orderNo
        };
      }
      if (data) {
        SeachParams = {
          merchantId: this.merchantId || "",
          uniqNo: data.uniqNo || "",
          orderNo: this.orderNo || "",
          opt: this.opt || "",
          serType: this.serType || "",
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams.disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      this.formData = SeachParams
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    handleChange(val) {
      this.merchantId = val
      console.log(this.merchantId)
    },
    handleChangeSerType(val) {
      this.serType = val
    },
    handleChangeOpt(val) {
      this.opt = val
      this.serType = ""
      _api.getSerTypeList({ opt: this.opt,accountType: 'platformFinance' }).then((res) => {
        if (res.code === 1) {
          this.serTypeList = res.data
        }
      })
    },
    exportBill() {
      console.log(this.formData);
      let params = {
        accountType: "platformFinance",
        merchantId: this.merchantId || "",
        storeId: this.storeId || "",
        uniqNo: this.formData.uniqNo || "",
        orderNo: this.formData.orderNo || "",
        opt: this.formData.opt || "",
        serType: this.serType || "",
        startTime: this.formData.startTime ? this.formData.startTime : "",
        endTime: this.formData.endTime ? this.formData.endTime : "",
        pageNum: 1,
        pageSize: 10,
      };
      this.exportLoading = true;
      _api.getFinanceExcel(params).then(res => {
        console.log(res)
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "服务异常");
            }
          } catch (err) {
            const fileName = "账单.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      }).finally(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .balance-form {
    /deep/.el-form-item__label {
      line-height: 32px;
    }

    /deep/.el-form-item__content {
      line-height: 32px;
    }
  }
}
</style>
